html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('./background.png') no-repeat center center fixed;
  background-size: cover;
  filter: blur(2px);
  z-index: -1;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

header {
  background-color: transparent;
}

nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

nav a:hover {
  text-decoration: underline;
}

main {
  margin-top: 20px;
  flex-grow: 1;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  border: none;
  background-color: transparent;
  max-height: 400px;
}

.logo img {
  max-height: 100px;
}

footer {
  color: white;
  width: 100%;
  padding: 1rem 0;
}

.sponsors {
  margin-bottom: 2rem;
}

.social-icons a {
  color: white;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #007bff;
}

.sponsor-img {
  max-height: 100px;
  max-width: 100px;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #007bff;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
  font-size: 2rem;
}

.scroll-to-top:hover {
  background: #0056b3;
}

.btn-outline-primary {
  color: white;
  border-color: white;
}

.btn-outline-primary:hover {
  background-color: white;
  color: black;
}

.text-white {
  color: white !important;
}

@media (min-width: 2560px) {
  header .container {
    max-width: 1200px;
  }

  .logo img {
    max-height: 150px;
  }

  nav a {
    font-size: 1.5rem;
  }

  main {
    margin-top: 40px;
  }

  .image-container img {
    max-height: 600px;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  .btn {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
  }

  footer {
    font-size: 1.25rem;
  }
}

/* New styles for enlarging and enhancing the text and button */
.main-text {
  font-size: 4rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.sub-text {
  font-size: 2.5rem;
  font-weight: normal;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.enhanced-button {
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 8px;
  background-color: #007bff;
  border: none;
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.enhanced-button:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}
